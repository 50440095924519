import { backend } from "../../../config";

export const getAllCategories = async () => {
  const response = await backend.get("categories");
  //check response
  if (response?.data.code === 200) {
    return response.data;
  } else {
    throw new Error(response?.data.code);
  }
};
