import { backend } from "../../../config";

export const getAllUsers = async () => {
  const response = await backend.get("users");
  //check response
  if (response?.data.code === 200) {
    return response.data;
  } else {
    throw new Error(response?.data.code);
  }
};

export const getUserWatchLog = async ({ queryKey }) => {
  const id = queryKey[1];
  const response = await backend.get("user/watch-log/" + id);
  //check response
  if (response?.data.code === 200) {
    return response.data;
  } else {
    throw new Error(response?.data.code);
  }
};
