import React, { useState } from "react";
import { Button, Form, Input, Typography } from "antd";
import AuthLayout from "../components/layout/AuthLayout";
import { backend } from "../config";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setIsLoading(true);
    backend
      .post("login", values)
      .then((response) => {
        setIsLoading(false);
        if (response.data.code !== 200) {
          setErrorMessage(response.data.errorMessage);
        } else {
          //localStorage.setItem("accessToken", response.data.data.accessToken);
          //localStorage.setItem("refreshToken", response.data.data.refreshToken);
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("error ", error);
        setIsLoading(false);
        setErrorMessage(error.code);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AuthLayout>
      <div className="flex justify-center pt-20">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
          initialValues={{
            size: "large",
          }}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Username is required field!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required field!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
          {errorMessage && (
            <div className="mt-[-20px]">
              <Typography.Text type="danger">{errorMessage}</Typography.Text>
            </div>
          )}
        </Form>
      </div>
    </AuthLayout>
  );
};

export default Login;
