import { Outlet } from "react-router-dom";
import React, { createElement, useState } from "react";
import { useNavigate, useOutlet } from "react-router-dom";
import { YoutubeOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Menu, theme, Button } from "antd";
import Blank from "./Blank";
import { backend } from "../config";

const { Header, Footer, Sider } = Layout;

const Root = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async () => {
    setIsLoading(true);
    await backend
      .get("logout")
      .then((response) => {
        setIsLoading(false);
        navigate("/login");
      })
      .catch((error) => {
        console.log("error ", error);
        setIsLoading(false);
      });
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const outlet = useOutlet();

  const onLogoClickHandler = () => {
    navigate("/");
  };

  return (
    <Layout hasSider>
      <Sider breakpoint="sm" collapsedWidth="0">
        <div
          onClick={onLogoClickHandler}
          className="cursor-pointer text-white text-2xl flex justify-center p-4"
        >
          Cocoon
        </div>
        <Menu
          theme="dark"
          mode="inline"
          items={[
            {
              key: "1",
              icon: createElement(YoutubeOutlined),
              label: `Videos`,
              onClick: () => {
                navigate("/videos");
              },
            },
            {
              key: "2",
              icon: createElement(UserOutlined),
              label: `Users`,
              onClick: () => {
                navigate("/users");
              },
            },
          ]}
        />
      </Sider>

      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div className="absolute right-0">
            <Button
              onClick={handleLogout}
              size="large"
              type="link"
              loading={isLoading}
            >
              Logout
            </Button>
          </div>
        </Header>
        {outlet ? <Outlet /> : <Blank />}

        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Cocoon App ©2023 Created by Clover Studio
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Root;
