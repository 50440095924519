import React from "react";
import { Layout, theme } from "antd";
import styles from "./AuthLayout.module.scss";

const { Content, Header } = Layout;

function AuthLayout({ children, header }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <div className="flex items-center h-full">
          <div className="text-black text-xl ml-8 font-bold">Cocoon</div>
        </div>
      </Header>
      <Layout>
        <Content className={styles.layout}>{children}</Content>
      </Layout>
    </Layout>
  );
}

export default AuthLayout;
