import React from "react";
import { useLocation } from "react-router-dom";
import { Layout, Typography } from "antd";
import { Col, Row } from "antd";
import styles from "./VideoDetails.module.scss";

const { Content } = Layout;

const VideoDetails = () => {
  const location = useLocation();
  const { video } = location.state;
  const {
    title,
    youtubeURL,
    tags,
    category,
    question,
    description,
    answers,
    duration,
    thumb,
    numberOfTimesRecommended,
    numberOfTimesWatched,
  } = video;
  return (
    <Content
      style={{
        margin: "24px 16px 0",
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <div>
          <Typography.Title level={2}>Video details</Typography.Title>
        </div>
        <div className="w-2/3 mt-12">
          <Row gutter={[0, 12]} justify="center">
            <Col span={8}>
              <b>Title</b>
            </Col>
            <Col span={16}>{title}</Col>
            <Col span={8}>
              <b>Video URL</b>
            </Col>
            <Col span={16}>
              <a href={youtubeURL} target="_blank" rel="noopener noreferrer">
                {youtubeURL}
              </a>
            </Col>
            <Col span={8}>
              <b>Description</b>
            </Col>
            <Col span={16}>{description}</Col>
            <Col span={8}>
              <b>Tags</b>
            </Col>
            <Col span={16}>{tags.join(" ")}</Col>
            <Col span={8}>
              <b>Category</b>
            </Col>
            <Col span={16}>{category}</Col>
            <Col span={8}>
              <b>Duration</b>
            </Col>
            <Col span={16}>{duration}</Col>
            <Col span={8}>
              <b>Thumb</b>
            </Col>
            <Col span={16}>{thumb}</Col>
            <Col span={8}>
              <b>Question</b>
            </Col>
            <Col span={16}>{question}</Col>
            <Col span={8}>
              <b>Number of times recommended</b>
            </Col>
            <Col span={16}>{numberOfTimesRecommended}</Col>
            <Col span={8}>
              <b>Number of times watched</b>
            </Col>
            <Col span={16}>{numberOfTimesWatched}</Col>
            <Col span={8}>
              <b>Answers</b>
            </Col>
            <Col span={16}>
              <div>
                <table className={styles.tableStyle}>
                  <thead className={styles.thStyle}>
                    <tr>
                      <th>Answer</th>
                      <th>Correctness</th>
                    </tr>
                  </thead>
                  <tbody>
                    {answers.map((answer) => (
                      <tr key={answer.option}>
                        <td>{answer.option}</td>
                        <td>{answer.isCorrect ? "TRUE" : "FALSE"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Content>
  );
};

export default VideoDetails;
