import React, { useMemo } from "react";
import { Layout, Table, Typography, Space } from "antd";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../utils/api/user/userAPIs";
import { isMobile } from "react-device-detect";
import { DownloadOutlined } from "@ant-design/icons";
import { backend } from "../../config";

const { Content } = Layout;

const UserList = () => {
  const userListQuery = useQuery(["UserList"], getAllUsers, {
    initialData: { users: [] },
    initialDataUpdatedAt: 0,
  });

  const data = useMemo(() => {
    const data = userListQuery.data.data?.map((object) => {
      const { _id, phoneNumber, createdAt, ...rest } = object;
      return {
        key: _id,
        _id: _id,
        phoneNumber: phoneNumber,
        createdAt: createdAt,
        ...rest,
      };
    });
    return data;
  }, [userListQuery.data?.data]);

  const handleWatchLogDownload = () => {
    backend({
      url: process.env.REACT_APP_BACKEND_URL + "watch-logs",
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <Content
      style={{
        margin: "24px 16px 0",
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <Typography.Title level={2}>Users</Typography.Title>
        <div className="relative h-8 w-full">
          <div
            onClick={handleWatchLogDownload}
            className="absolute  right-4 cursor-pointer"
          >
            <div className="flex justify-center items-center">
              <DownloadOutlined style={{ fontSize: "16px", color: "#08c" }} />
              <p className="text-[#08c]">
                {" "}
                &nbsp;Download all users watch logs
              </p>
            </div>
          </div>
        </div>
      </div>

      <Table
        dataSource={data}
        columns={[
          {
            title: "_id",
            dataIndex: "_id",
            key: "_id",
            responsive: ["sm"],
          },
          {
            title: "Phone number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
          },
          {
            title: "Created at",
            dataIndex: "createdAt",
            key: "createdAt",
          },
          {
            title: "",
            key: "action",
            render: (_, record) => (
              <Space size="large">
                <Link
                  to={"/users/details/" + record._id}
                  state={{ user: record }}
                >
                  See more
                </Link>
                <Link to={"/users/log/" + record._id}>Watch log</Link>
              </Space>
            ),
          },
        ]}
        scroll={isMobile ? { x: "max-content" } : {}}
      />
    </Content>
  );
};

export default UserList;
