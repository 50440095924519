import React from "react";
import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const RouteGuard = ({ children }) => {
  const [cookies] = useCookies(["logged_in"]);

  function hasJWT() {
    let flag = false;
    //check user has JWT token
    //localStorage.getItem("accessToken") ? (flag = true) : (flag = false);
    cookies.logged_in ? (flag = true) : (flag = false);
    return flag;
  }

  return hasJWT() ? (
    // User has JWT token, render children
    children
  ) : (
    // User doesn't have JWT token, redirect to "/login"
    <Navigate to={{ pathname: "/login" }} />
  );
};

export default RouteGuard;
