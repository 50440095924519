import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Typography } from "antd";
import { Col, Row } from "antd";
import { useQuery } from "@tanstack/react-query";
import { getAllCategories } from "../../utils/api/category/categoryAPIs";

const { Content } = Layout;

const UserDetails = () => {
  const location = useLocation();
  const { user } = location.state;
  const { age, gender, grade, school, categories, parent } = user;

  const categoriesQuery = useQuery(
    ["UserDetailsCategories"],
    getAllCategories,
    {
      initialData: { categories: [] },
      initialDataUpdatedAt: 0,
    }
  );

  const categoriesMemoizedOptions = useMemo(() =>
    categoriesQuery.data?.data
      ?.filter((category) => categories.includes(category._id.toString()))
      .map((category) => {
        return category.name;
      })
  );

  return (
    <Content
      style={{
        margin: "24px 16px 0",
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <div>
          <Typography.Title level={2}>User details</Typography.Title>
        </div>
        <div className="flex justify-center w-2/3 mt-12">
          <Row gutter={[0, 12]} justify="center">
            <Col span={8}>
              <b>Age</b>
            </Col>
            <Col span={16}>{age || "No data"}</Col>
            <Col span={8}>
              <b>Gender</b>
            </Col>
            <Col span={16}>{gender || "No data"}</Col>
            <Col span={8}>
              <b>Grade</b>
            </Col>
            <Col span={16}>{grade || "No data"}</Col>
            <Col span={8}>
              <b>School</b>
            </Col>
            <Col span={16}>{school || "No data"}</Col>
            <Col span={8}>
              <b>Parent</b>
            </Col>
            <Col span={16}>{parent || "No data"}</Col>
            <Col span={8}>
              <b>Categories</b>
            </Col>
            <Col span={16}>
              {categoriesMemoizedOptions?.join(", ") || "No data"}
            </Col>
          </Row>
        </div>
      </div>
    </Content>
  );
};

export default UserDetails;
