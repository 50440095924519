import React from "react";
import { Layout, Typography } from "antd";
const { Content } = Layout;

const Blank = () => {
  return (
    <Content
      style={{
        margin: "24px 16px 0",
      }}
    >
      <div className="min-h-screen">
        <Typography.Title level={2}>
          Welcome to Cocoon Admin page !
        </Typography.Title>
      </div>
    </Content>
  );
};

export default Blank;
