import { calculateDuration } from "../../../utils/helpers";

export const fillFormWithApiData = (form, videoData, setImageUrl) => {
  const title = videoData.data.data.snippet?.title;
  if (title) form.setFieldValue("title", title);
  const description = videoData.data.data.snippet?.description;
  if (description) form.setFieldValue("description", description);
  const tags = +videoData.data.data.snippet?.tags?.join(" #");
  if (tags) form.setFieldValue("tags", "#" + tags);

  let duration = videoData.data.data.contentDetails?.duration;
  if (duration) duration = calculateDuration(duration);

  form.setFieldValue("duration", duration);
  form.setFieldValue("channelId", videoData.data.data.snippet?.channelId);
  form.setFieldValue("youtubeVideoId", videoData.data.data.id);

  form.setFieldValue(
    "thumb",
    videoData.data.data.snippet?.thumbnails.maxres?.url ||
      videoData.data.data.snippet?.thumbnails.standard?.url ||
      videoData.data.data.snippet?.thumbnails.high?.url
  );

  setImageUrl(
    videoData.data.data.snippet?.thumbnails.maxres?.url ||
      videoData.data.data.snippet?.thumbnails.standard?.url ||
      videoData.data.data.snippet?.thumbnails.high?.url
  );
};
