import React, { useMemo } from "react";
import { Layout, Table, Typography } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useParams, Link } from "react-router-dom";
import { getUserWatchLog } from "../../utils/api/user/userAPIs";
import { isMobile } from "react-device-detect";
import { DownloadOutlined } from "@ant-design/icons";
import { backend } from "../../config";

const { Content } = Layout;

const UserWatchLog = () => {
  let { id } = useParams();

  const logListQuery = useQuery(["UserWatchLog", id], getUserWatchLog, {
    initialData: [],
    initialDataUpdatedAt: 0,
  });

  const data = useMemo(() => {
    const data = logListQuery.data?.data?.map((object) => {
      const { _id, portion, createdAt, updatedAt, video, rating, answer } =
        object;

      return {
        key: _id,
        title: video.title,
        youtubeURL: video.youtubeURL,
        portion: portion,
        createdAt: createdAt,
        updatedAt: updatedAt,
        rating: rating || "no rating yet",
        answer: answer?.toString() || "no answer yet",
      };
    });
    return data;
  }, [logListQuery.data?.data]);

  const handleWatchLogDownload = () => {
    backend({
      url: process.env.REACT_APP_BACKEND_URL + "user/watch-log/download/" + id,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <Content
      style={{
        margin: "24px 16px 0",
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <Typography.Title level={2}>User watch log</Typography.Title>
        <div className="relative h-8 w-full">
          <div
            onClick={handleWatchLogDownload}
            className="absolute  right-4 cursor-pointer"
          >
            <div className="flex justify-center items-center">
              <DownloadOutlined style={{ fontSize: "16px", color: "#08c" }} />
              <p className="text-[#08c]"> &nbsp;Download user watch logs</p>
            </div>
          </div>
        </div>
      </div>

      <Table
        dataSource={data}
        columns={[
          {
            title: "Video title",
            key: "action",
            render: (_, record) => (
              <Link to={"/videos/" + record._id}>{record.title}</Link>
            ),
          },
          {
            title: "Youtube URL",
            dataIndex: "youtubeURL",
            key: "youtubeURL",
            //width: 250, // Specify the width for the column
            //ellipsis: true, // Enable ellipsis for long URLs
            render: (text) => (
              <a href={text} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            ),
          },

          {
            title: "Watch portion",
            dataIndex: "portion",
            key: "portion",
          },
          {
            title: "Rating",
            dataIndex: "rating",
            key: "rating",
          },
          {
            title: "Answer",
            dataIndex: "answer",
            key: "answer",
          },
          {
            title: "Created at",
            dataIndex: "createdAt",
            key: "createdAt",
          },
          {
            title: "Updated at",
            dataIndex: "updatedAt",
            key: "updatedAt",
          },
        ]}
        scroll={isMobile ? { x: "max-content" } : {}}
      />
    </Content>
  );
};

export default UserWatchLog;
