import { backend } from "../../../config";

export const getAllVideos = async () => {
  const response = await backend.get("videos");
  //check response
  if (response?.data.code === 200) {
    return response.data;
  } else {
    throw new Error(response?.data.code);
  }
};

export const postVideo = async (video) => {
  const response = await backend.post("video", video);
  //check response
  if (response?.data.code === 200) {
    const newVideoList = await getAllVideos();
    return newVideoList;
  } else {
    throw new Error(response?.data.code);
  }
};

export const deleteVideo = async (videoId) => {
  const response = await backend.delete("video/" + videoId);
  //check response
  if (response?.data.code === 200) {
    const newVideoList = await getAllVideos();
    return newVideoList;
  } else {
    throw new Error(response?.data.code);
  }
};

export const patchVideo = async ({ videoId, video }) => {
  const response = await backend.patch("video/" + videoId, video);
  //check response
  if (response?.data.code === 200) {
    const newVideoList = await getAllVideos();
    return newVideoList;
  } else {
    throw new Error(response?.data.code);
  }
};

export const getVideoData = async (videoId) => {
  const response = await backend.get("videos");
  //check response
  if (response?.data.code === 200) {
    return response.data;
  } else {
    throw new Error(response?.data.code);
  }
};
