import React from "react";
import { Modal } from "antd";
import VideoModalForm from "./VideoModalForm";

const VideoModal = ({
  visible,
  setVisible,
  video,
  postVideoMutation,
  patchVideoMutation,
}) => {
  const isEdit = Boolean(video);

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <Modal
        title={isEdit ? "Edit video" : "Add video"}
        open={visible}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
      >
        <VideoModalForm
          isEdit={isEdit}
          postVideoMutation={postVideoMutation}
          setVisible={setVisible}
          video={video}
          patchVideoMutation={patchVideoMutation}
        />
      </Modal>
    </>
  );
};

export default VideoModal;
