import axios from "axios";
import { redirect } from "react-router-dom";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

instance.defaults.headers.common["Content-Type"] = "application/json";

export const refreshAccessTokenFn = async () => {
  const response = await instance.get("refresh-token");
  if (response.data.code === 9999) {
    return redirect("/login");
  }
  return response.data;
};

instance.interceptors.response.use(
  async (response) => {
    /*const originalRequest = response.config;
    const errorCode = response.data?.code;
    console.log("9999? ", errorCode);
    if (errorCode === 9999 && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshAccessTokenFn();
      return instance(originalRequest);
    }*/
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const errorCode = error.response?.data.code;
    if (errorCode === 9999 && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshAccessTokenFn();
      return instance(originalRequest);
    }
    // 100004 is error code for bad credentials -> return response to display error message to user
    if (errorCode === 100004) return error.response;
    else return Promise.reject(error);
  }
);

export default instance;
