import React, { useEffect, useState, useMemo } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space, Upload } from "antd";
import { useQuery } from "@tanstack/react-query";
import { arraysOfObjectsEqual } from "../../utils/helpers";
import { backend } from "../../config";
import { fillFormWithApiData } from "./helpers/data";
import { getAllCategories } from "../../utils/api/category/categoryAPIs";

const { TextArea } = Input;

const VideoModalForm = ({
  isEdit,
  postVideoMutation,
  patchVideoMutation,
  setVisible,
  video,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const categoriesQuery = useQuery(["Categories"], getAllCategories, {
    initialData: { categories: [] },
    initialDataUpdatedAt: 0,
  });

  const categoriesMemoizedOptions = useMemo(
    () =>
      categoriesQuery.data?.data
        ?.filter((category) => category.name !== "Discover")
        .map((category) => {
          return { name: category.name, _id: category._id };
        }),
    [categoriesQuery?.data]
  );

  const checkChanges = (values) => {
    for (const property in video) {
      if (
        property === "tags" &&
        JSON.stringify(video[property]) === JSON.stringify(values[property])
      ) {
        delete values[property];
      } else if (
        property === "answers" &&
        arraysOfObjectsEqual(video[property], values[property])
      ) {
        delete values[property];
      } else if (video[property] === values[property]) {
        delete values[property];
      }
    }
    return values;
  };

  const onFinish = (values) => {
    const valuesChanged = checkChanges(values);

    if (isEdit)
      patchVideoMutation.mutate({ videoId: video._id, video: valuesChanged });
    else postVideoMutation.mutate(values);
    setVisible(false);
    form.resetFields();
  };

  const handleGetVideoData = async () => {
    const youtubeURL = form.getFieldValue("youtubeURL");

    //https://www.youtube.com/watch?v=3L9WL1z6wUY&ab_channel=KaiCenat
    const videoID = youtubeURL?.split("?v=")[1]?.split("&ab_channel")[0];
    setIsLoading(true);
    const videoData = await backend.get("video?id=" + videoID);
    setIsLoading(false);

    fillFormWithApiData(form, videoData, setImageUrl);
  };

  useEffect(() => {
    if (video) {
      form.setFieldsValue(video);
      //tags are passed as an array but a string is needed
      form.setFieldValue("tags", video.tags.join(" "));
      setImageUrl(video.thumb);
    } else {
      form.resetFields();
    }
    return () => {
      setImageUrl();
      form.resetFields();
    };
  }, [video, form]);

  return (
    <Form layout="vertical" onFinish={onFinish} autoComplete="off" form={form}>
      <div className="flex items-center">
        <Form.Item
          className="basis-3/4"
          label="Youtube URL:"
          name="youtubeURL"
          rules={[{ required: true, message: "Youtube URL is required." }]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Button
          onClick={handleGetVideoData}
          className="ml-2 mt-2 basis-1/4"
          loading={isLoading}
        >
          GET VIDEO DATA
        </Button>
      </div>
      <Form.Item
        label="Title:"
        name="title"
        rules={[{ required: true, message: "Title is required." }]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Description:"
        name="description"
        rules={[{ required: true, message: "Description is required." }]}
      >
        <TextArea rows={3} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item hidden label="Channed ID:" name="channelId">
        <TextArea rows={3} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item hidden label="Youtube Video ID:" name="youtubeVideoId">
        <TextArea rows={3} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label="Tags: (#tag1 #tag2 ...)"
        name="tags"
        rules={[{ required: true, message: "Tags is required." }]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label="Duration:"
        name="duration"
        rules={[{ required: true, message: "Duration is required." }]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item label="Thumb:" name="thumb">
        <Upload
          disabled={true}
          listType="picture-card"
          showUploadList={false}
          fileList={false}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="thumb" style={{ width: "100%" }} />
          ) : (
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </div>
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        label="Category:"
        name="category"
        rules={[{ required: true, message: "Category is required." }]}
      >
        <Select>
          {categoriesMemoizedOptions?.map((category) => (
            <Select.Option key={category._id} value={category._id}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Question:"
        name="question"
        rules={[{ required: true, message: "Question is required." }]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.List
        name="answers"
        rules={[
          {
            validator: async (_, names) => {
              if (names?.length < 3) {
                return Promise.reject(
                  new Error("Minimum number of answer options is 3.")
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="center">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.option !== curValues.option ||
                    prevValues.isCorrect !== curValues.isCorrect
                  }
                >
                  {() => (
                    <Form.Item
                      {...field}
                      label="Possible answer"
                      name={[field.name, "option"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing option",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Correctness"
                  name={[field.name, "isCorrect"]}
                  rules={[
                    {
                      required: true,
                      message: "Missing correctness",
                    },
                  ]}
                >
                  <Select>
                    <Select.Option key={1} value={true}>
                      True
                    </Select.Option>
                    <Select.Option key={2} value={false}>
                      False
                    </Select.Option>
                  </Select>
                </Form.Item>

                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                disabled={fields?.length === 3}
                icon={<PlusOutlined />}
              >
                Add possible answer
              </Button>
            </Form.Item>

            <Form.ErrorList className="text-[#ff4d4f] mb-8" errors={errors} />
          </>
        )}
      </Form.List>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default VideoModalForm;
