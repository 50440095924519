import React, { useMemo, useState } from "react";
import { Layout, Button, Space, Table, Tag, Typography, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isMobile } from "react-device-detect";
import {
  getAllVideos,
  postVideo,
  deleteVideo,
  patchVideo,
} from "../../utils/api/video/videoAPIs";
import VideoModal from "./VideoModal";
import { backend } from "../../config";

const { Content } = Layout;

const VideoList = () => {
  const [visible, setVisible] = useState(false);
  const [videoEditData, setVideoEditData] = useState();

  const queryClient = useQueryClient();

  const videoList = useQuery(["VideoList"], getAllVideos, {
    initialData: { videos: [] },
    initialDataUpdatedAt: 0,
  });

  const postVideoMutation = useMutation((video) => postVideo(video), {
    onSuccess: (response) => {
      queryClient.setQueryData(["VideoList"], response);

      message.success({
        content: `Video added.`,
        key: `addVideo`,
        duration: 4,
      });
    },
    onError: () => {
      queryClient.invalidateQueries(["VideoList"]);

      message.error({
        content: `Adding video failed, try again later!`,
        key: `addVideoFail`,
        duration: 4,
      });
    },
  });

  const deleteVideoMutation = useMutation((videoId) => deleteVideo(videoId), {
    onSuccess: (response) => {
      queryClient.setQueryData(["VideoList"], response);

      message.success({
        content: `Video deleted.`,
        key: `deleteVideo`,
        duration: 4,
      });
    },
    onError: () => {
      queryClient.invalidateQueries(["VideoList"]);

      message.error({
        content: `Deleting video failed, try again later!`,
        key: `deleteVideoFail`,
        duration: 4,
      });
    },
  });

  const patchVideoMutation = useMutation(
    ({ videoId, video }) => patchVideo({ videoId, video }),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(["VideoList"], response);

        message.success({
          content: `Video changed.`,
          key: `patchVideo`,
          duration: 4,
        });
      },
      onError: () => {
        queryClient.invalidateQueries(["VideoList"]);

        message.error({
          content: `Changing video failed, try again later!`,
          key: `changeVideoFail`,
          duration: 4,
        });
      },
    }
  );

  const handleOpenModal = (video) => {
    if (video.key) setVideoEditData(video);
    else setVideoEditData();

    setVisible(true);
  };

  const handleDeleteVideo = (videoId) => {
    deleteVideoMutation.mutate(videoId);
  };

  const data = useMemo(() => {
    const data = videoList.data.data?.map((object) => {
      const {
        _id,
        title,
        youtubeURL,
        tags,
        category,
        question,
        description,
        answers,
        duration,
        thumb,
        numberOfTimesRecommended,
        numberOfTimesWatched,
      } = object;
      return {
        key: _id,
        _id,
        title,
        youtubeURL,
        tags: tags.split(" "),
        category: category.name,
        question,
        description,
        answers,
        duration,
        thumb,
        numberOfTimesRecommended,
        numberOfTimesWatched,
      };
    });
    return data;
  }, [videoList.data?.data]);

  const handleVideosDataDownload = () => {
    backend({
      url: process.env.REACT_APP_BACKEND_URL + "videos/data",
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <Content
      style={{
        margin: "24px 16px 0",
      }}
    >
      <div className="flex justify-center">
        <Typography.Title level={2}>Videos</Typography.Title>
      </div>

      <div className="w-full flex justify-between">
        <Button onClick={handleOpenModal} size="large" type="link">
          + Add video
        </Button>
        <div
          onClick={handleVideosDataDownload}
          className="absolute  right-4 cursor-pointer"
        >
          <div className="flex justify-center items-center">
            <DownloadOutlined style={{ fontSize: "16px", color: "#08c" }} />
            <p className="text-[#08c]"> &nbsp;Download all videos data</p>
          </div>
        </div>
      </div>
      <Table
        dataSource={data}
        columns={[
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
          },
          {
            title: "Youtube URL",
            dataIndex: "youtubeURL",
            key: "youtubeURL",
            //width: 250, // Specify the width for the column
            //ellipsis: true, // Enable ellipsis for long URLs
            render: (text) => (
              <a href={text} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            ),
          },
          {
            title: "Tags",
            dataIndex: "tags",
            key: "tags",
            render: (tags) => (
              <>
                {tags.map((tag) => (
                  <Tag color="blue" key={tag}>
                    {tag}
                  </Tag>
                ))}
              </>
            ),
            responsive: ["sm"],
          },
          {
            title: "Category",
            dataIndex: "category",
            key: "category",
            //width: 150, // Specify the width for the column
            //ellipsis: true, // Enable ellipsis for long URLs
            responsive: ["sm"],
          },
          {
            title: "",
            key: "action",
            width: "100",
            render: (_, record) => (
              <Space size="small">
                <Button
                  type="primary"
                  danger
                  ghost
                  onClick={() => handleDeleteVideo(record._id)}
                >
                  Delete
                </Button>
                <Button onClick={() => handleOpenModal(record)}>Edit</Button>
                <Link to={"/videos/" + record._id} state={{ video: record }}>
                  See more
                </Link>
              </Space>
            ),
          },
        ]}
        scroll={isMobile ? { x: "max-content" } : {}}
      />
      <VideoModal
        visible={visible}
        setVisible={setVisible}
        video={videoEditData}
        postVideoMutation={postVideoMutation}
        patchVideoMutation={patchVideoMutation}
      />
    </Content>
  );
};

export default VideoList;
