import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Root, Login } from "./routes";
import {
  VideoList,
  ErrorPage,
  RouteGuard,
  VideoDetails,
  UserDetails,
  UserWatchLog,
  UserList,
} from "./components";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RouteGuard>
        <Root />
      </RouteGuard>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "videos",
        element: (
          <RouteGuard>
            <VideoList />
          </RouteGuard>
        ),
      },
      {
        path: "videos/:id",
        element: (
          <RouteGuard>
            <VideoDetails />
          </RouteGuard>
        ),
      },
      {
        path: "users",
        element: (
          <RouteGuard>
            <UserList />
          </RouteGuard>
        ),
      },
      {
        path: "users/log/:id",
        element: (
          <RouteGuard>
            <UserWatchLog />
          </RouteGuard>
        ),
      },
      {
        path: "users/details/:id",
        element: (
          <RouteGuard>
            <UserDetails />
          </RouteGuard>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
